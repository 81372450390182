import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import RentPricingTable from '../components/pricing-tables/rent-pricing-table';

const IndexPage = () => (
  <Layout>
    <Helmet bodyAttributes={{ class: 'no-bg' }} />
    <RentPricingTable />
  </Layout>
);

export default IndexPage;
