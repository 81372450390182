import React, { useState, VoidFunctionComponent } from 'react';
import rentPricingTestData from '../../data/rent-pricing-test.json';
import rentPricingSkiData from '../../data/rent-pricing-ski.json';
import rentPricingSkiLongData from '../../data/rent-pricing-long.json';
import rentPricingSkiTestLongData from '../../data/rent-pricing-test.-long.json';

const RentPricingTable: VoidFunctionComponent = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  return (
    <section className="main-section main-section--center" id="cennik">
      <div className="container main-section__container">
        <div className="main-section__content" id="pozicovna">
          <h1 className="main-section__title main-section__title--center" data-title="Požičovňa">
            Požičovňa
          </h1>
          <p className="main-section__text main-section__text--center">
            Vynikajúci zážitok, osobný prístup, pomoc s výberom na mieru. Každý rok požičiavame to najlepšie z lyží a
            snowboardov.
          </p>
          <div className="tabs tabs--small tabs--center" role="tablist">
            <button
              type="button"
              role="tab"
              className={`tabs__item ${activeIndex === 1 && 'is-active'}`}
              onClick={(e) => {
                e.preventDefault();
                setActiveIndex(1);
              }}
            >
              Kratkodobá
            </button>
            <button
              type="button"
              role="tab"
              className={`tabs__item ${activeIndex === 2 && 'is-active'}`}
              onClick={(e) => {
                e.preventDefault();
                setActiveIndex(2);
              }}
            >
              Dlhodobá
            </button>
          </div>
          <div className="tables">
            {activeIndex === 1 && (
              <>
                <h2>Testovacie lyže</h2>
                <table className="servis-table is-active" role="tabpanel">
                  <thead className="servis-table__head">
                    {rentPricingTestData.header.map((values) => {
                      return (
                        <tr className="servis-table__row" key={values.toString()}>
                          {values.map((value) => {
                            return (
                              <th className="servis-table__cell" key={`${value}-${values.toString()}`}>
                                {value}
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody className="servis-table__body">
                    {rentPricingTestData.body.map((values) => {
                      return (
                        <tr className="servis-table__row" key={values.toString()}>
                          {values.map((value) => (
                            <td className="servis-table__cell" key={`${value}-${values.toString()}`}>
                              {value}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="table-mark">* Lyže, lyžiarky, paličky</div>
                <div className="table-mark">Platba požičovne aj zálohy možná aj kartou</div>
                <div className="table-mark">Pri platbe zálohy kartou bude účtovaný poplatok 2% z platby</div>
                <h2 style={{ marginTop: 40 }}>Lyže a snowboardy</h2>
                <table className="servis-table is-active" role="tabpanel">
                  <thead className="servis-table__head">
                    {rentPricingSkiData.header.map((values) => (
                      <tr className="servis-table__row" key={values.toString()}>
                        {values.map((value) => {
                          return (
                            <th className="servis-table__cell" key={`${value}-${values.toString()}`}>
                              {value}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="servis-table__body">
                    {rentPricingSkiData.body.map((values) => {
                      return (
                        <tr className="servis-table__row" key={values.toString()}>
                          {values.map((value) => (
                            <td className="servis-table__cell" key={`${value}-${values.toString()}`}>
                              {value}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
            {activeIndex === 2 && (
              <>
                <h2>Testovacie lyže</h2>
                <table className="servis-table is-active" role="tabpanel">
                  <thead className="servis-table__head">
                    {rentPricingSkiTestLongData.header.map((values) => {
                      return (
                        <tr className="servis-table__row" key={values.toString()}>
                          {values.map((value) => {
                            return (
                              <th className="servis-table__cell" key={`${value}-${values.toString()}`}>
                                {value}
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody className="servis-table__body">
                    {rentPricingSkiTestLongData.body.map((values) => {
                      return (
                        <tr className="servis-table__row" key={values.toString()}>
                          {values.map((value) => (
                            <td className="servis-table__cell" key={`${value}-${values.toString()}`}>
                              {value}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="table-mark">* Lyže, lyžiarky, paličky</div>
                <div className="table-mark">Platba požičovne aj zálohy možná aj kartou</div>
                <div className="table-mark">Pri platbe zálohy kartou bude účtovaný poplatok 2% z platby</div>
                <h2 style={{ marginTop: 40 }}>Lyže a snowboardy</h2>
                <table className="servis-table is-active" role="tabpanel">
                  <thead className="servis-table__head">
                    {rentPricingSkiLongData.header.map((values) => (
                      <tr className="servis-table__row" key={values.toString()}>
                        {values.map((value, index) => {
                          return (
                            <th className="servis-table__cell" key={`${value}-${index}`}>
                              {value}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="servis-table__body">
                    {rentPricingSkiLongData.body.map((values) => {
                      return (
                        <tr className="servis-table__row" key={values.toString()}>
                          {values.map((value, index) => (
                            <td className="servis-table__cell" key={`${value}-${index}`}>
                              {value}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
            <div className="table-mark">* Lyže, lyžiarky, paličky</div>
            <div className="table-mark">Platba požičovne aj zálohy možná aj kartou</div>
            <div className="table-mark">Pri platbe zálohy kartou bude účtovaný poplatok 2% z platby</div>
          </div>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <a className="button button--primary" href="https://services.bookio.com/slyze/widget?lang=sk">
              Rezervovať termín
            </a>
            <div style={{ marginTop: '10px' }}>
              <a target="_blank" rel="noopener noreferrer" href="/Podmienky požičania výstroje.pdf">
                Podmienky požičania výstroje
              </a>
            </div>
          </div>
        </div>
        <div className="video-container">
          <iframe
            style={{ width: '100%' }}
            src="https://www.youtube-nocookie.com/embed/_xI6BTgHhrw?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default RentPricingTable;
